<template>
  <div>
    <div style="position: relative;">
      <div
        v-for="quarter of quarters()"
        :key="quarter"
        :style="getLineQuarterStyle(quarter)"
      ></div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { BackendMixin } from '@/mixins/backend'
import { diffTimes, displayHourMin } from '@/utils/time'

export default {
  name: 'NurseryInscriptionLine',
  components: {},
  mixins: [BackendMixin],
  props: {
    startTime: String,
    endTime: String,
    smallDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    quarters() {
      let limitDuration = (diffTimes(this.endTime, this.startTime) / 3600)
      return Array.from(Array(limitDuration * 4).keys())
    },
    getMinutes(startTime) {
      return displayHourMin(startTime).split(':')[1]
    },
    getLineQuarterStyle(quarter) {
      // let limitDuration = 1 + (diffTimes(limit.endTime, limit.startTime) / 3600)
      let cellWidth = 100 / this.quarters().length
      const hourColor = 'solid 2px #888'
      const halfColor = 'solid 1px #aaa'
      const quarterColor = 'dashed 1px #aaa'
      const quarter3Color = 'dashed 1px #aaa'
      const minutes = this.getMinutes(this.startTime)
      let colors = [hourColor, quarterColor, halfColor, quarter3Color]
      if (minutes === '15') {
        colors = [quarterColor, halfColor, quarter3Color, hourColor]
      } else if (minutes === '30') {
        colors = [halfColor, quarter3Color, hourColor, quarterColor]
      } else if (minutes === '45') {
        colors = [quarter3Color, hourColor, quarterColor, halfColor]
      }
      const color = colors[((quarter + 1) % 4)]
      const left = +quarter * +cellWidth + 0.5
      const style = {
        'display': 'inline-block',
        'width': '' + cellWidth + '%',
        'position': 'absolute',
        'left': '' + left + '%',
        'border-right': color,
        height: this.smallDisplay ? '31px' : '45px',
      }
      if (quarter === 0) {
        style['border-left'] = colors[0]
      }
      return style
    },
  },
  mounted() {
  },
}
</script>
<style lang="less" scoped>
</style>
