<template>
  <span class="cancel-nursery-inscriptions-modal">
    <b-modal
      :id="modalId"
      cancel-title="Non"
      @ok.prevent="onConfirm"
      @cancel="onCancel"
      ok-variant="primary"
      ok-title="Oui"
      @show="onShown"
    >
      <template v-slot:modal-title>
        <i class="fa fa-trash-arrow-up"></i>
        Annuler toutes les inscriptions
      </template>
      <div v-if="errorText" class="error-text" style="margin-bottom: 5px;">
        {{ errorText }}
      </div>
      <div v-if="day">
        <div v-if="offDay" class="off-day-warning">
          Le {{ day | dateToString('dddd LL') }}, fermeture {{ offDay.getOffPeriod() }}
        </div>
        <div v-else class="off-day-info">
          Le {{ day | dateToString('dddd LL') }} n'est pas un jour de fermeture.
        </div>
      </div>
      <b>
        Voulez-vous supprimer <counter-label :counter="inscriptions.length" label="inscription"></counter-label>
      </b>
      <div class="select-option">
        <b-form-select id="cancelOption" v-model="cancelOption" :disabled="partial">
          <b-form-select-option :value="0">Annuler les inscriptions</b-form-select-option>
          <b-form-select-option :value="1">Passer en absence remboursée</b-form-select-option>
        </b-form-select>
      </div>
      <div v-if="offDay && offDay.isPartial()">
        <b-form-checkbox id="partial" v-model="partial">
          <b>Seulement pour la période de fermeture</b>
        </b-form-checkbox>
        <div v-if="partial" class="off-day-warning">
          Les enfants seront désinscrits et réinscrits sur le nouveau créneau
        </div>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { BackendApi } from '@/utils/http'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeNurseryOffDay } from '@/types/nursery'
import { dateToString } from '@/filters/texts'

export default {
  name: 'cancel-nursery-inscriptions-modal',
  components: {
    CounterLabel,
  },
  mixins: [BackendMixin],
  props: {
    modalId: {
      type: String,
      default: 'bv-cancel-nursery-inscriptions-modal',
    },
    inscriptions: Array,
    nurseryId: Number,
    day: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errorText: '',
      cancelOption: 0,
      offDay: null,
      partial: false,
    }
  },
  watch: {
    day: function() {
      this.loadOffDay()
    },
    partial: function() {
      this.cancelOption = 0
    },
  },
  computed: {
  },
  methods: {
    onShown() {
      this.loadOffDay()
    },
    async loadOffDay() {
      this.errorText = ''
      this.offDay = null
      this.partial = false
      if (this.day) {
        let url = '/nursery/api/get-off-day/' + this.nurseryId + '/' + this.day + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          if (resp.data.day) {
            this.offDay = makeNurseryOffDay(resp.data.day)
            if (this.offDay.isPartial()) {
              this.partial = true
            }
          }
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async onConfirm() {
      this.errorText = ''
      let url = '/nursery/api/cancel-inscriptions/'
      const backendApi = new BackendApi('post', url)
      let inscriptionIds = this.inscriptions.map(ins => ins.id)
      try {
        const data = {
          inscriptions: inscriptionIds,
          absence: (+this.cancelOption === 1),
        }
        if (this.offDay && this.offDay.isPartial() && this.partial) {
          data.off_day = dateToString(this.offDay.date, 'YYYY-MM-DD')
          data.start_time = this.offDay.startTime
          data.end_time = this.offDay.endTime
        }
        await backendApi.callApi(data)
        this.$emit('done')
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    onCancel() {
    },
  },
}
</script>
<style lang="less" scoped>
.off-day-info, .off-day-warning {
  margin-bottom: 5px;
  padding: 5px;
  background: #ccc;
  font-size: 14px;
  font-weight: bold;
}
.off-day-warning {
  background: #eac672;
}
.select-option {
  margin: 5px 0;
}
</style>
