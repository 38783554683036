<template>
  <div class="nursery">
    <page-header :title="title" icon="fa fa-baby-carriage" :links="links"></page-header>
    <div class="nursery-days small-text">
      <b-row>
        <b-col>
          <b-form-select
            id="statusFilter"
            v-model="statusFilter"
            :class="{ highlighted: statusFilter > 0, }"
          >
            <b-form-select-option :value="elt.id" v-for="elt in statusFilters" :key="elt.id">
              {{ elt.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <input
              type="text"
              v-model="nameFilter"
              id="name-filter"
              class="form-control"
              placeholder="Nom ou prénom"
            />
        </b-col>
        <b-col cols="2">
          <choices-select
            id="orderBy" :choices="ordering" v-model="orderBy"
          >
          </choices-select>
        </b-col>
        <b-col sm="2" md="2">
          <b-form-checkbox
            id="show-birthdays"
            v-model="showBirthdays"
            :disabled="birthdayCount === 0"
            class="small"
          >
            <counter-label :counter="birthdayCount" label="anniversaire" name0="Aucun">
            </counter-label>
          </b-form-checkbox>
        </b-col>
        <b-col cols="4">
          <date-selector v-model="dayDateAsString" id="seanceDay"></date-selector>
        </b-col>
      </b-row>
      <div style="background: #e0e0e0; padding: 2px 10px;">
        <b-row>
          <b-col>
            <b-form-checkbox
              id="show-parent-contact"
              v-model="showParentContact"
            >
              Parents
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="showFields"
              v-model="showFields"
            >
              Données enfants
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="showPlanning"
              v-model="showPlanning"
            >
              Planning
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="showPrePlanning"
              v-model="showPrePlanning"
              v-if="showPlanning"
            >
              Prévu
            </b-form-checkbox>
            <b-form-checkbox
              id="showPreDuration"
              v-model="showPreDuration"
              v-if="showPlanning && showPrePlanning"
            >
              Durée
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="clocking"
              v-model="clocking"
              :disabled="!showPlanning"
            >
              <span v-if="isFuture">Horaires</span>
              <span v-else>Pointage</span>
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="printLandscape"
              v-model="printLandscape"
            >
              Impression paysage
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="smallDisplay"
              v-model="smallDisplay"
            >
              Affichage réduit
            </b-form-checkbox>
          </b-col>
          <b-col cols="1">
            <b-form-select
              id="sliceSize"
              v-model="sliceSize"
              class="small-select"
            >
              <b-form-select-option :value="15">1/4h</b-form-select-option>
              <b-form-select-option :value="30">1/2h</b-form-select-option>
              <!--<b-form-select-option :value="60">1h</b-form-select-option>-->
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <div ref="printMe">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div ref="excelMe" v-if="!isLoading(loadingName)">
          <div class="nursery-date-header">
            <div style="display: inline-block; width: 40%">
              <span class="day-date">{{ dayDate|dateToString('dddd LL') }}</span>
              <span v-if="offDay" class="off-day-message">
                fermeture {{ offDay.getOffPeriod() }}
              </span>
            </div>
            <div style="display: inline-block; width: 59%; text-align: right;">
              <b>
                  <counter-label :counter="presences" label="enfant présent" label-n="enfants présents"></counter-label>
                  <span v-if="absences">
                    -
                    <counter-label :counter="absences" label="absent" ></counter-label>
                  </span>
                </b>
            </div>
          </div>
          <table class="table table-striped table-hover" :class="smallDisplay ? 'small-table' : 'medium-table'">
            <tr class="th-dark" v-if="filteredElements.length">
              <th></th>
              <th v-if="showFields"></th>
              <th v-if="showFields"></th>
              <th v-if="showParentContact"></th>
              <th v-if="showPlanning && showPrePlanning && !showPreDuration" colspan="2"></th>
              <th v-if="showPlanning && showPrePlanning && showPreDuration"></th>
              <th v-if="showPlanning" :style="planningStyle">
                <nursery-inscription-line-caption
                  :day="dayDateAsString"
                  :limits="limits"
                ></nursery-inscription-line-caption>
              </th>
              <th v-if="!showPlanning">Absence</th>
              <th v-if="!showPlanning" colspan="2">
                Prévu
              </th>
              <th v-if="!showPlanning" colspan="2">
                Réel
              </th>
              <th v-if="!showPlanning" colspan="2">
                Facturé
              </th>
            </tr>
            <tr class="th-dark" v-if="showPlanning && filteredElements.length">
              <th></th>
              <th v-if="showFields"></th>
              <th v-if="showFields"></th>
              <th v-if="showParentContact"></th>
              <th v-if="showPlanning && showPrePlanning && !showPreDuration" colspan="2">
                Prévu
              </th>
              <th v-if="showPlanning && showPrePlanning && showPreDuration">
                Prévu: {{ duration(globalDuration())}}
              </th>
              <th :style="planningStyle">
                <nursery-inscription-header
                  :day="dayDateAsString"
                  :limits="limits"
                  :elements="elements"
                  :slice-size="sliceSize"
                  @click="selectedItem = $event.item"
                ></nursery-inscription-header>
              </th>
            </tr>
            <tr v-if="filteredElements.length === 0">
              <td style="width: 100%; text-align: center;">
                <div class="empty-text">
                  <span v-if="statusFilter">Aucun enfant ne correspond à ce filtre</span>
                  <span v-else-if="elements.length > 0">
                    Aucun enfant à cet horaire. <a href @click.prevent="selectedItem = null">supprimer le filtre</a>
                  </span>
                  <span v-else-if="elements.length === 0">Aucun inscrit</span>
                </div>
              </td>
            </tr>
            <tr v-for="elt of filteredElements" :key="elt.id">
              <td>
                <nursery-fields :fields="markFields" :element="elt" show-as-mark>
                </nursery-fields>
                <a
                  href
                  @click.prevent="showIndividualSidebar(elt.individual, true)"
                  :class="{ baby: elt.individual.getMonthAgeValue() < 18, except: outOfContract(elt) }"
                >
                  {{ elt.individual.lastAndFirstName() }}
                </a>
                <span
                  v-if="getAdaptation(elt)"
                  class="badge"
                  :class="getAdaptationBadge(elt)"
                  title="Adaptation"
                  v-b-tooltip
                >
                  <i class="fa" :class="getAdaptationIcon(elt)"></i> {{ getAdaptation(elt) }}
                </span>
              </td>
              <td v-if="showFields">
                {{ elt.individual.getBabyAgeOn(day) }}
                <span
                  v-if="showIndividualBirthday(elt)"
                  v-b-tooltip="'Joyeux anniversaire ' + elt.individual.firstName"
                >
                  <i class="fa fa-birthday-cake"></i>
                </span>
              </td>
              <td v-if="showFields">
                <nursery-fields :fields="rowFields" :element="elt"></nursery-fields>
              </td>
              <td v-if="showParentContact">
                <a href @click.prevent="showEntity(elt)">
                  <nursery-parents :element="elt"></nursery-parents>
                </a>
              </td>
              <td v-if="showPlanning && showPrePlanning && !showPreDuration">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.arrivalAt, '-') }}
                </div>
              </td>
              <td v-if="showPlanning && showPrePlanning && !showPreDuration">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.leavingAt, '-') }}
                </div>
              </td>
              <td v-if="showPlanning && showPrePlanning && showPreDuration">
                {{ duration(totalDuration(elt.inscriptions)) }}
              </td>
              <td :style="planningStyle" v-if="showPlanning">
                <div v-if="elt.inscriptions.length">
                  <nursery-inscription-line
                    :element="elt"
                    :day="dayDateAsString"
                    :limits="limits"
                    :edit="clocking"
                    :edited="currentClocking"
                    :small-display="smallDisplay"
                    @click="showInscriptionClocking($event)"
                    @clocking="onInscriptionClocking($event)"
                  ></nursery-inscription-line>
                </div>
                <div v-else style="text-align: center">
                  <a href @click.prevent="createNewInscription(elt)">
                    Inscrire
                  </a>
                </div>
              </td>
              <td v-if="!showPlanning">
                <span v-if="hasAbsence(elt)">{{ absenceType(elt) }}</span>
              </td>
              <td v-if="!showPlanning">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.arrivalAt, '-') }}
                </div>
              </td>
               <td v-if="!showPlanning">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.leavingAt, '-') }}
                </div>
              </td>
              <td v-if="!showPlanning">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.arrivedAt, '-') }}
                </div>
              </td>
              <td v-if="!showPlanning">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.leftAt, '-') }}
                </div>
              </td>
              <td v-if="!showPlanning">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.paidArrivedAt, '-') }}
                </div>
              </td>
              <td v-if="!showPlanning">
                <div v-for="ins of elt.inscriptions" :key="ins.id">
                  {{ displayHourMin(ins.paidLeftAt, '-') }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <nursery-set-inscription-clocking-modal
        id="bv-clocking-modal"
        :inscription="selectedInscription"
        :tracks="selectedTracks"
        :allow-cancel="canDelete"
        @confirmed="patchInscription($event)"
        @split="onSplitInscription($event)"
      ></nursery-set-inscription-clocking-modal>
      <nursery-create-one-inscription-modal
        id="bv-create-one-nursery-inscription-modal"
        :nurseries="[nursery]"
        :individual="selectedIndividual"
        :parent-inscription="selectedParentInscription"
        :entities="selectedEntities"
        :date="dayDateAsString"
        @confirmed="addInscription($event)"
      ></nursery-create-one-inscription-modal>
      <confirm-modal
        name="set-default-clocking"
        title="Définir le pointage par défaut"
        :text="setDefaultClockingText"
        @confirmed="onDefaultClockingConfirmed"
      >
      </confirm-modal>
      <cancel-nursery-inscriptions-modal
        modal-id="bv-cancel-nursery-inscriptions-modal"
        :inscriptions="selectedInscriptions"
        :day="dayDateAsString"
        :nursery-id="+nurseryId"
        @done="onCancelConfirmed"
      >
      </cancel-nursery-inscriptions-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import ChoicesSelect from '@/components/Controls/ChoicesSelect.vue'
import DateSelector from '@/components/Controls/DateSelector.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import NurseryInscriptionLine from '@/components/Nursery/NurseryInscriptionLine.vue'
import NurseryInscriptionLineCaption from '@/components/Nursery/NurseryInscriptionLineCaption.vue'
import NurseryFields from '@/components/Nursery/NurseryFields.vue'
import NurseryParents from '@/components/Nursery/NurseryParents.vue'
import NurserySetInscriptionClockingModal from '@/components/Nursery/NurserySetInscriptionClockingModal.vue'
import NurseryInscriptionHeader from '@/components/Nursery/NurseryInscriptionHeader.vue'
import NurseryCreateOneInscriptionModal from '@/components/Nursery/NurseryCreateOneInscriptionModal.vue'
import CancelNurseryInscriptionsModal from '@/components/Nursery/CancelNurseryInscriptionsModal.vue'
import { dateToString, duration } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import {
  NurseryContractType, makeNurseryInscription, makeNurseryLimit, NurseryAbsence, makeNursery,
  NurseryAdaptation, makeNurseryOffDay
} from '@/types/nursery'
import { makeEntity, makeIndividual } from '@/types/people'
import { makeDailyListField } from '@/types/youth'
import { distinct, distinctString } from '@/utils/arrays'
import { diffDate, isBirthday } from '@/utils/dates'
import { BackendApi, openDocument } from '@/utils/http'
import { compareDates } from '@/utils/sorting'
import { includes } from '@/utils/strings'
import { displayHourMin, compareTimes, addMinutes, removeMinutes } from '@/utils/time'
import { sum } from '@/utils/math'

export default {
  name: 'nursery-inscriptions',
  mixins: [BackendMixin],
  components: {
    CancelNurseryInscriptionsModal,
    DateSelector,
    ConfirmModal,
    NurseryCreateOneInscriptionModal,
    NurseryInscriptionHeader,
    ChoicesSelect,
    NurserySetInscriptionClockingModal,
    NurseryParents,
    NurseryFields,
    NurseryInscriptionLineCaption,
    NurseryInscriptionLine,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
    nurseryId: [String, Number],
    day: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      loadingName: 'nursery-inscriptions',
      dayDateAsString: '',
      originalDate: '',
      elements: [],
      nameFilter: '',
      limits: [],
      showBirthdays: false,
      statusFilter: 0,
      showParentContact: false,
      showFields: false,
      showPlanning: true,
      showPrePlanning: false,
      showPreDuration: false,
      printLandscape: false,
      fields: [],
      selectedInscription: null,
      selectedTracks: 1,
      nursery: makeNursery({ id: this.nurseryId, name: 'Crèche', }),
      ordering: [
        makeChoice({ id: 1, name: 'Alphabétique', }),
        makeChoice({ id: 2, name: 'Heure d\'arrivée', }),
        makeChoice({ id: 3, name: 'Heure de départ', }),
        makeChoice({ id: 4, name: 'Âge', })
      ],
      orderBy: null,
      selectedItem: '',
      selectedIndividual: null,
      selectedEntities: [],
      selectedParentInscription: null,
      clocking: false,
      currentClocking: null,
      clockingTimeout: 0,
      smallDisplay: false,
      offDay: null,
      sliceSize: 30,
    }
  },
  watch: {
    dayDate: async function(newValue) {
      let isValid = false
      if (newValue) {
        isValid = moment(newValue, 'YYYY-MM-DD').isValid()
      }
      if (isValid) {
        let newDate = dateToString(moment(newValue, 'YYYY-MM-DD').toDate(), 'YYYY-MM-DD')
        this.patchUrl(newDate)
        await this.loadOffDay(newValue)
        await this.loadInscriptions(newValue)
      } else {
        this.dayDateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
      }
    },
    elements: function() {},
    smallDisplay: function() {
      window.localStorage.setItem('nursery-smallDisplay', this.smallDisplay ? 'oui' : '')
    },
    sliceSize: function() {
      window.localStorage.setItem('nursery-sliceSize', '' + this.sliceSize)
    },
  },
  computed: {
    isFuture() {
      if (this.dayDate) {
        const today = dateToString(moment().toDate(), 'YYYY-MM-DD')
        return diffDate(today, this.dayDate) < 0
      }
      return false
    },
    startTime() {
      if (this.limits.length) {
        return this.limits[0].startTime
      }
      return ''
    },
    endTime() {
      if (this.limits.length) {
        return this.limits[this.limits.length - 1].endTime
      }
      return ''
    },
    title() {
      let title = this.nursery.getLabel() + ' - ' + dateToString(this.dayDateAsString, 'ddd D MMM YYYY')
      if (this.startTime && this.endTime) {
        title += ' - ' + displayHourMin(this.startTime) + ' - ' + displayHourMin(this.endTime)
      }
      return title
    },
    dayDate() {
      return moment(this.dayDateAsString, 'YYYY-MM-DD').toDate()
    },
    presences() {
      let counter = 0
      for (const elt of this.elements.filter(elt => elt.inscriptions.length > 0)) {
        if (!this.hasAbsence(elt)) {
          counter += 1
        }
      }
      return counter
    },
    absences() {
      let counter = 0
      for (const elt of this.elements.filter(elt => elt.inscriptions.length > 0)) {
        if (this.hasAbsence(elt)) {
          counter += 1
        }
      }
      return counter
    },
    filteredElements() {
      let elements = this.elements
      if (this.nameFilter) {
        elements = elements.filter(
          elt => {
            return includes(elt.individual.lastAndFirstName(), this.nameFilter)
          }
        )
      }
      if (this.selectedItem) {
        elements = elements.filter(
          elt => {
            return elt.inscriptions.filter(
              ins => ins.isHereOnSlice(this.selectedItem)
            ).length > 0
          }
        )
      }
      if (this.statusFilter !== 6) {
        elements = elements.filter(
          elt => {
            return elt.inscriptions.length > 0
          }
        )
      }
      if (this.statusFilter) {
        if (this.statusFilter === 1) {
          // Présents
          elements = elements.filter(
            elt => {
              return !this.hasAbsence(elt)
            }
          )
        } else if (this.statusFilter === 2) {
          // Absents
          elements = elements.filter(
            elt => {
              return this.hasAbsence(elt)
            }
          )
        } else if (this.statusFilter === 3) {
          // Encore Présents
          elements = elements.filter(
            elt => {
              return this.isStillHere(elt)
            }
          )
        } else if (this.statusFilter === 4) {
          // Partis
          elements = elements.filter(
            elt => {
              return this.isGone(elt)
            }
          )
        } else if (this.statusFilter === 5) {
          // Pas encore arrivés
          elements = elements.filter(
            elt => {
              return this.notArrived(elt)
            }
          )
        } else if (this.statusFilter === 6) {
          // Pas encore arrivés
          elements = elements.filter(
            elt => {
              return elt.inscriptions.length === 0
            }
          )
        }
      }
      if (this.showBirthdays) {
        elements = elements.filter(
          elt => isBirthday(elt.individual.birthDate, this.dayDate)
        )
      }
      return this.sortElements(elements)
    },
    birthdayCount() {
      return this.filteredElements.filter(
        elt => isBirthday(elt.individual.birthDate, this.dayDate)
      ).length
    },
    statusFilters() {
      return [0, 1, 5, 2, 3, 4, 6].map(
        index => makeChoice({ id: index, name: this.getStatusLabel(index), })
      )
    },
    planningStyle() {
      let width = 80
      if (this.showParents) {
        width -= 10
      }
      if (this.showFields) {
        width -= 10
      }
      return {
        padding: '0.5em 0.1em',
        width: '' + width + '%',
      }
    },
    links() {
      const isLoading = this.isLoading(this.loadingName)
      const excelDisabled = isLoading || this.showPlanning
      const links = [
        {
          id: 3,
          label: 'Pointage par défaut',
          callback: this.showConfirmDefaultClocking,
          icon: 'fa fa-clock',
          cssClass: (isLoading) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (this.hasPerm('nursery.delete_nurseryinscription')) {
        links.push(
          {
            id: 4,
            label: 'Tout annuler',
            callback: this.showCancelAll,
            icon: 'fa fa-trash-arrow-up',
            cssClass: (isLoading) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      links.push(
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (isLoading) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (excelDisabled) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      )
      return links
    },
    markFields() {
      return this.fields.filter(elt => elt.showAsMark)
    },
    rowFields() {
      return this.fields.filter(elt => !elt.showAsMark)
    },
    setDefaultClockingText() {
      return (
        'Le pointage sera effectué avec les heures d\'arrivée et de départ prévue' +
        '\n\nÊtes-vous sûr de vouloir forcer le pointage?'
      )
    },
    selectedInscriptions() {
      let inscriptions = []
      for (const elt of this.filteredElements) {
        inscriptions = inscriptions.concat(elt.inscriptions)
      }
      return inscriptions
    },
    canDelete() {
      return this.hasPerm('nursery.delete_nurseryinscription')
    },
  },
  async mounted() {
    this.dayDateAsString = this.day || dateToString(moment().toDate(), 'YYYY-MM-DD')
    this.originalDate = this.day
    this.smallDisplay = !!window.localStorage.getItem('nursery-smallDisplay')
    this.sliceSize = (+window.localStorage.getItem('nursery-sliceSize')) || 30
    await this.loadNursery()
    await this.loadOffDay(this.day)
    await this.loadInscriptions(this.day)
  },
  methods: {
    duration,
    displayHourMin,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    patchUrl(newValue) {
      // met à jour l'url (sans rechargement) avec la date sélectionné si
      // différente de la date du jour
      let initialPath = this.$route.path
      let today = dateToString(moment().toDate(), 'YYYY-MM-DD')
      let updatedPath = ''
      if (this.originalDate) {
        updatedPath = initialPath.replace(this.originalDate, newValue)
      } else {
        if (newValue === today) {
          updatedPath = initialPath
        } else {
          updatedPath = initialPath + '/' + newValue
        }
      }
      history.pushState(
        {},
        null,
        updatedPath
      )
    },
    getAdaptation(elt) {
      let adaptations = elt.inscriptions.filter(ins => ins.adaptation)
      if (adaptations.length) {
        return distinctString(adaptations.map(ins => ins.getAdaptationShortLabel())).join(', ')
      }
      return ''
    },
    getAdaptationBadge(elt) {
      let adaptations = elt.inscriptions.filter(ins => ins.adaptation === NurseryAdaptation.WithoutParents)
      if (adaptations.length) {
        return 'badge-danger'
      }
      return 'badge-warning'
    },
    getAdaptationIcon(elt) {
      let adaptations = elt.inscriptions.filter(ins => ins.adaptation === NurseryAdaptation.WithoutParents)
      if (adaptations.length) {
        return 'fa-baby'
      }
      return 'fa-children'
    },
    hasAbsence(elt) {
      return elt.inscriptions.filter(ins => ins.absence !== NurseryAbsence.None).length > 0
    },
    absenceType(elt) {
      const isPaid = elt.inscriptions.filter(ins => ins.absence === NurseryAbsence.Paid).length > 0
      return isPaid ? 'Absence facturée' : 'Absence'
    },
    isStillHere(elt) {
      return elt.inscriptions.filter(ins => ins.arrivedAt && !ins.leftAt).length > 0
    },
    isGone(elt) {
      let wasHere = elt.inscriptions.filter(ins => ins.arrivedAt).length > 0
      let isNotHere = elt.inscriptions.filter(ins => ins.arrivedAt && !ins.leftAt).length === 0
      return wasHere && isNotHere
    },
    notArrived(elt) {
      return elt.inscriptions.filter(ins => !ins.absence && !ins.arrivedAt).length > 0
    },
    async showEntity(elt) {
      const entities = distinct(elt.inscriptions.map(ins => ins.entity))
      if (entities.length === 1) {
        this.showEntitySidebar(entities[0])
      }
    },
    patchInscription(event) {
      let found = false
      const inscription = event.inscription
      const elements = this.elements.filter(elt => elt.individual.id === inscription.individual.id)
      if (elements.length === 1) {
        const element = elements[0]
        const index = element.inscriptions.map(ins => ins.id).indexOf(inscription.id)
        if (index >= 0) {
          if (inscription.cancelledOn) {
            element.inscriptions.splice(index, 1)
          } else {
            element.inscriptions[index] = inscription
          }
          // force refresh
          element.inscriptions = [].concat(element.inscriptions)
          found = true
          this.elements = [].concat(this.elements)
        }
      }
      if (!found) {
        this.refreshInscriptions()
      }
    },
    onSplitInscription(event) {
      this.refreshInscriptions()
    },
    showConfirmDefaultClocking() {
      this.$bvModal.show('bv-confirm-modal:set-default-clocking')
    },
    showCancelAll() {
      this.$bvModal.show('bv-cancel-nursery-inscriptions-modal')
    },
    async onCancelConfirmed() {
      await this.refreshInscriptions()
    },
    async loadOffDay(seanceDate) {
      this.offDay = null
      let dateAsTr = dateToString(seanceDate, 'YYYY-MM-DD')
      let url = '/nursery/api/get-off-day/' + this.nurseryId + '/' + dateAsTr + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        if (resp.data.day) {
          this.offDay = makeNurseryOffDay(resp.data.day)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onDefaultClockingConfirmed() {
      this.startLoading(this.loadingName)
      let url = '/nursery/api/set-default-clocking/'
      const backendApi = new BackendApi('post', url)
      let inscriptionIds = []
      for (const elt of this.filteredElements) {
        inscriptionIds = inscriptionIds.concat(elt.inscriptions.map(ins => ins.id))
      }
      try {
        const data = {
          inscriptions: inscriptionIds,
        }
        await backendApi.callApi(data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      await this.refreshInscriptions()
    },
    addInscription(event) {
      let found = false
      const inscription = event.inscription
      const elements = this.elements.filter(elt => elt.individual.id === inscription.individual.id)
      if (elements.length === 1) {
        const element = elements[0]
        element.inscriptions.push(inscription)
        found = true
        this.elements = [].concat(this.elements)
      }
      if (!found) {
        this.refreshInscriptions()
      }
    },
    refreshInscriptions() {
      this.loadInscriptions(this.day)
    },
    async loadNursery() {
      this.startLoading(this.loadingName)
      let url = '/nursery/api/nurseries/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const nurseries = resp.data.filter(
          elt => elt.id === +this.nurseryId
        ).map(
          elt => makeNursery(elt)
        )
        if (nurseries.length > 0) {
          this.nursery = nurseries[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadInscriptions(seanceDate) {
      this.startLoading(this.loadingName)
      let dateAsTr = dateToString(seanceDate, 'YYYY-MM-DD')
      let url = '/nursery/api/nursery-date-inscriptions/' + this.nurseryId + '/' + dateAsTr + '/' + dateAsTr + '/'
      const backendApi = new BackendApi('get', url)
      this.elements = []
      try {
        const resp = await backendApi.callApi()
        this.fields = resp.data.fields.map(elt => makeDailyListField(elt))
        this.limits = resp.data.limits.map(elt => makeNurseryLimit(elt))
        this.elements = resp.data.elements.map(
          elt => {
            const inscriptions = elt.inscriptions.map(ins => makeNurseryInscription(ins))
            let arrivalAt = ''
            let leavingAt = ''
            if (inscriptions.length) {
              let lastIndex = inscriptions.length - 1
              arrivalAt = inscriptions[0].arrivalAt
              leavingAt = inscriptions[lastIndex].leavingAt
            }
            return {
              individual: makeIndividual(elt.individual),
              entities: elt.entities.map(makeEntity),
              inscriptions: inscriptions,
              fieldTexts: elt['field_texts'],
              fieldValues: elt['field_values'],
              fieldComments: elt['field_comments'],
              arrivalAt: arrivalAt,
              leavingAt: leavingAt,
            }
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    showIndividualBirthday(elt) {
      return isBirthday(elt.individual.birthDate, this.dayDate)
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/?colors=1'
      if (this.printLandscape) {
        docUrl += '&landscape=1'
      }
      const docSlug = 'creche-' + moment(this.dayDateAsString).format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'creche-' + moment(this.dayDateAsString).format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    showInscriptionClocking(event) {
      this.selectedInscription = event.inscription
      let tracks = this.elements.filter(
        elt => elt.individual.id === this.selectedInscription.individual.id
      ).filter(
        elt => elt.inscriptions.length > 1
      )
      this.selectedTracks = (tracks.length) ? 2 : 1
      this.$bvModal.show('bv-clocking-modal')
    },
    onInscriptionClocking(event) {
      const action = event.action
      const inscription = event.inscription
      this.currentClocking = event.inscription
      const clockingStep = 15
      switch (action) {
        case 'earlierArrival':
          let arrival = this.isFuture ? inscription.arrivalAt : inscription.arrivedAt
          if (arrival) {
            arrival = removeMinutes(arrival, clockingStep)
          } else {
            arrival = inscription.arrivalAt
          }
          if (compareTimes(this.startTime, arrival) < 0) {
            arrival = this.startTime
          }
          if (this.isFuture) {
            inscription.arrivalAt = arrival
          } else {
            inscription.arrivedAt = arrival
          }
          break
        case 'laterArrival':
          let arrival2 = this.isFuture ? inscription.arrivalAt : inscription.arrivedAt
          if (arrival2) {
            arrival2 = addMinutes(arrival2, clockingStep)
          } else {
            arrival2 = inscription.arrivalAt
          }
          if (compareTimes(arrival2, inscription.getLeaving2()) < 0) {
            arrival2 = inscription.getLeaving2()
          }
          if (this.isFuture) {
            inscription.arrivalAt = arrival2
          } else {
            inscription.arrivedAt = arrival2
          }
          break
        case 'earlierLeaving':
          const modify = this.isFuture || !inscription.arrivedAt
          let leaving = modify ? inscription.leavingAt : inscription.leftAt
          if (leaving) {
            leaving = removeMinutes(leaving, clockingStep)
          } else {
            leaving = inscription.leavingAt
          }
          if (compareTimes(leaving, inscription.getArrival2()) > 0) {
            leaving = inscription.getArrival2()
          }
          if (modify) {
            inscription.leavingAt = leaving
          } else {
            inscription.leftAt = leaving
          }
          break
        case 'laterLeaving':
          const modify2 = this.isFuture || !inscription.arrivedAt
          let leaving2 = modify2 ? inscription.leavingAt : inscription.leftAt
          if (leaving2) {
            leaving2 = addMinutes(leaving2, clockingStep)
          } else {
            leaving2 = inscription.leavingAt
          }
          if (compareTimes(leaving2, this.endTime) < 0) {
            leaving2 = this.endTime
          }
          if (modify2) {
            inscription.leavingAt = leaving2
          } else {
            inscription.leftAt = leaving2
          }
          break
      }
      this.resetClockingDelay()
      this.waitForClockingDelay()
    },
    createNewInscription(elt) {
      this.selectedIndividual = elt.individual
      if (elt.inscriptions.length > 0) {
        this.selectedEntities = []
        this.selectedParentInscription = elt.inscriptions[0]
      } else {
        this.selectedEntities = elt.entities
        this.selectedParentInscription = null
      }
      this.$nextTick(
        () => {
          this.$bvModal.show('bv-create-one-nursery-inscription-modal')
        }
      )
    },
    getStatusLabel(statusFilter) {
      switch (statusFilter) {
        case 0:
          return 'Tous'
        case 1:
          return 'Présents'
        case 2:
          return 'Absents'
        case 3:
          return 'Encore présents'
        case 4:
          return 'Pointage complet'
        case 5:
          return 'Pas encore arrivé'
        case 6:
          return 'Non inscrits'
      }
      return '????'
    },
    sortElements(elements) {
      const orderBy = this.orderBy ? this.orderBy.id : 1
      if (orderBy === 2) {
        return elements.sort(
          (elt1, elt2) => compareTimes(elt2.arrivalAt, elt1.arrivalAt)
        )
      }
      if (orderBy === 3) {
        return elements.sort(
          (elt1, elt2) => compareTimes(elt2.leavingAt, elt1.leavingAt)
        )
      }
      if (orderBy === 4) {
        return elements.sort(
          (elt1, elt2) => compareDates(elt2.individual.birthDate, elt1.individual.birthDate)
        )
      }
      return elements
    },
    outOfContract(elt) {
      return elt.inscriptions.filter(
        sub => !sub.contract || sub.contract.contractType === NurseryContractType.TimeToTime
      ).length > 0
    },
    resetClockingDelay() {
      if (this.clockingTimeout) {
        clearTimeout(this.clockingTimeout)
        this.clockingTimeout = 0
      }
    },
    waitForClockingDelay() {
      this.clockingTimeout = setTimeout(
        () => {
          this.saveClocking()
        },
        3000
      )
    },
    async saveClocking() {
      if (this.currentClocking) {
        try {
          const url = '/nursery/api/set-inscription-clocking/' + this.currentClocking.id + '/'
          const backendApi = new BackendApi('post', url)
          const data = {}
          if (this.isFuture) {
            data['arrival_at'] = this.currentClocking.arrivalAt
            data['leaving_at'] = this.currentClocking.leavingAt
          } else {
            data['arrived_at'] = this.currentClocking.arrivedAt || null
            data['left_at'] = this.currentClocking.leftAt || null
          }
          const resp = await backendApi.callApi(data)
          // const inscription = makeNurseryInscription(resp.data)
          this.currentClocking = null
          let text = 'Le pointage a été prise en compte'
          if (this.isFuture) {
            text = 'La modification a été prise en compte'
          }
          await this.addSuccess(text)
        } catch (err) {
          let errorText = this.getErrorText(err)
          await this.addError(errorText)
        }
      }
    },
    totalDuration(inscriptions) {
      return sum(inscriptions.map(elt => elt.getInscriptionDuration()))
    },
    globalDuration() {
      let total = 0
      for (const elt of this.filteredElements) {
        total += this.totalDuration(elt.inscriptions)
      }
      return total
    },
  },
}
</script>

<style lang="less" scoped>
  .nursery-date-header {
    margin-top: 15px;
    padding: 2px 10px;
    background: #c0c0c0;
  }
  tr.th-dark th {
    background: #e0e0e0;
  }
  a.baby {
    color: #ff4961 !important;
  }
  a.except {
    background: #fbff8d !important;
  }
  .day-date {
    font-weight: bold;
    padding: 1px 0;
    font-size: 18px;
  }
  .off-day-message {
    font-weight: bold;
    padding: 1px 10px;
    margin: 0 5px;
    background: #656565;
    color: #fff;
    vertical-align: top;
    display: inline-block;
    border-radius: 4px;
  }
</style>
