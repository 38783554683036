<template>
  <div>
    <div
      v-for="limit of dailyLimits"
      :key="limit.id"
      class="ins-line"
    >
      <nursery-inscription-line-grid
        :start-time="limit.startTime"
        :end-time="limit.endTime"
        :small-display="smallDisplay"
      >
      </nursery-inscription-line-grid>
      <div
        v-for="inscription of dailyInscriptions"
        :key="inscription.id"
        :style="getLineStyle(dailyInscriptions)"
      >
       <div
         style="position: absolute; top: 2px; left: 1px;"
         class="ins-clocking"
         v-show="edit"
       >
         <i
           v-show="canEdit(inscription)"
           :class="{ active: isActive(inscription), }"
           class="fa fa-minus-circle no-print"
           @click.prevent="earlierArrival(inscription)"
         ></i>
         <i
           v-show="canEdit(inscription)"
           :class="{ active: isActive(inscription), }"
           class="fa fa-plus-circle no-print"
           @click.prevent="laterArrival(inscription)"
         ></i>
         <div class="ins-time">
           {{ displayHourMin(inscription.getArrival2()) }}
         </div>
       </div>
       <div
         :style="getInscriptionStyle(inscription, limit)"
         :title="getInscriptionTitle(inscription)"
         v-b-tooltip
         @click.prevent="onClick(inscription)"
       >
         <div v-if="inscription.absence && inscription.absenceReason">
          {{ inscription.absenceReason.name }}
         </div>
         <div v-else>
           {{ getInscriptionDuration(inscription) }}
         </div>
       </div>
       <div
         style="position: absolute; top: 2px; right: 1px;"
         class="ins-clocking"
         v-show="edit"
       >
         <i
           v-show="canEdit(inscription)"
           :class="{ active: isActive(inscription), }"
           class="fa fa-minus-circle no-print"
           @click.prevent="earlierLeaving(inscription)"
         ></i>
         <i
           v-show="canEdit(inscription)"
           :class="{ active: isActive(inscription), }"
           class="fa fa-plus-circle no-print"
           @click.prevent="laterLeaving(inscription)"
         ></i>
         <div class="ins-time">
           {{ displayHourMin(inscription.getLeaving2()) }}
         </div>
       </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import NurseryInscriptionLineGrid from '@/components/Nursery/NurseryInscriptionLineGrid.vue'
import { BackendMixin } from '@/mixins/backend'
import { NurseryAbsence } from '@/types/nursery'
import { compareDates } from '@/utils/sorting'
import { getWeekDay } from '@/utils/dates'
import { compareTimes, diffTimes, displayHourMin } from '@/utils/time'

export default {
  name: 'NurseryInscriptionLine',
  components: { NurseryInscriptionLineGrid, },
  mixins: [BackendMixin],
  props: {
    element: Object,
    limits: Array,
    day: String,
    edit: Boolean,
    smallDisplay: {
      type: Boolean,
      default: false,
    },
    edited: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      canChange: false,
    }
  },
  watch: {
    element: function() {},
  },
  computed: {
    dailyLimits() {
      return this.limits.filter(elt => elt.day === getWeekDay(this.day))
    },
    dailyInscriptions() {
      return this.element.inscriptions.filter(
        elt => compareDates(elt.date, this.day) === 0
      )
    },
  },
  methods: {
    displayHourMin,
    ...mapActions(['addError', 'addSuccess']),
    getInscriptionStyle(inscription, limit) {
      let arrival = inscription.arrivedAt || inscription.arrivalAt
      let leaving = inscription.leftAt || inscription.leavingAt
      let isArrived = !!inscription.arrivedAt
      let hasLeft = !!inscription.leftAt
      let start = ''
      let end = ''
      let limitDuration = diffTimes(limit.endTime, limit.startTime)
      if (arrival && leaving && limitDuration) {
        if (compareTimes(arrival, limit.startTime) <= 0) {
          // l'arrivée est après le début de la période
          if (compareTimes(arrival, limit.endTime) >= 0) {
            // et l'arrivée est avant la fin de la période
            // donc le début est l'arrivée
            start = arrival
            if (compareTimes(leaving, limit.endTime) > 0) {
              // le départ est avant la fin de période
              end = leaving
            } else {
              // le départ est après la fin de période
              end = limit.endTime
            }
          }
        } else {
          // l'arrivée est avant le début de la période
          if (compareTimes(leaving, limit.startTime) <= 0) {
            // et le départ après le début de période
            // donc le début est le début de période
            start = limit.startTime
            if (compareTimes(leaving, limit.endTime) > 0) {
              // le départ est avant la fin de période
              end = leaving
            } else {
              // le départ est après la fin de période
              end = limit.endTime
            }
          }
        }
        if (start && end) {
          let arrivalDuration = diffTimes(start, limit.startTime)
          let stayDuration = diffTimes(end, start)
          let offset = Math.round(100 * 100 * arrivalDuration / limitDuration) / 100
          let width = Math.round(100 * 100 * stayDuration / limitDuration) / 100
          let color = '#808080'
          if (inscription.absence === NurseryAbsence.Paid) {
            color = '#db5454'
          } else if (inscription.absence === NurseryAbsence.Refund) {
            color = '#edb15b'
          } else if (isArrived) {
            color = hasLeft ? '#5dd05d' : '#5fa9d2'
          }
          let border = 'none'
          if (this.edited && this.edited.id === inscription.id) {
            border = 'solid 3px #222'
          }
          return {
            'display': 'inline-block',
            'background': color,
            'position': 'absolue',
            'width': '' + width + '%',
            'margin-left': '' + (offset + 0.25) + '%',
            'height': '15px',
            'font-size': '10px',
            'border-radius': '2px',
            'cursor': 'pointer',
            'text-align': 'center',
            'color': '#fff',
            'border': border,
          }
        }
      }
      return {
        'display': 'none',
      }
    },
    getInscriptionTitle(inscription) {
      let arrival = inscription.arrivedAt || inscription.arrivalAt
      let leaving = inscription.leftAt || inscription.leavingAt
      let isArrived = !!inscription.arrivedAt
      let hasLeft = !!inscription.leftAt
      let title = ''
      if (inscription.absence === NurseryAbsence.Refund) {
        title = 'Absence '
      } else if (inscription.absence === NurseryAbsence.Paid) {
        title = 'Absence facturée '
      }
      if (isArrived) {
        title += 'Arrivée: ' + displayHourMin(arrival)
      } else {
        title += 'Arrivée prévue: ' + displayHourMin(arrival)
      }
      if (hasLeft) {
        title += ' Départ: ' + displayHourMin(leaving)
      } else {
        title += ' Départ prévu: ' + displayHourMin(leaving)
      }
      return title
    },
    getInscriptionDuration(inscription) {
      const duration = inscription.getListingDuration()
      if (duration) {
        return '' + duration + 'h'
      }
      return ''
    },
    onClick(inscription) {
      return this.$emit('click', { inscription: inscription, })
    },
    earlierArrival(inscription) {
      this.$emit('clocking', { inscription, action: 'earlierArrival', })
    },
    laterArrival(inscription) {
      this.$emit('clocking', { inscription, action: 'laterArrival', })
    },
    earlierLeaving(inscription) {
      this.$emit('clocking', { inscription, action: 'earlierLeaving', })
    },
    laterLeaving(inscription) {
      this.$emit('clocking', { inscription, action: 'laterLeaving', })
    },
    getLineStyle(dailyInscriptions) {
      let width = 0
      if (this.dailyLimits.length) {
        // width = Math.round(100 * 99 / this.dailyLimits.length) / 100
        width = Math.round(10000 / this.dailyLimits.length) / 100
      }
      const style = {
        'display': 'inline-block',
        'width': '' + width + '%',
        'position': 'relative',
      }
      if (dailyInscriptions.length > 1) {
        style['min-height'] = '40px'
      }
      return style
    },
    canEdit(inscription) {
      return (
        (this.canChange) &&
        (this.edited === null || this.edited.id === inscription.id)
      )
    },
    isActive(inscription) {
      return (
        this.edited && (this.edited.id === inscription.id)
      )
    },
  },
  mounted() {
    this.canChange = this.hasPerm('nursery.change_nurseryinscription')
  },
}
</script>
<style lang="less" scoped>
.ins-clocking {
  min-width: 50px;
}
.ins-clocking i {
  margin-top: -8px;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.3;
}
.ins-clocking i.active {
  opacity: 1;
}
.ins-clocking i.fa-minus-circle {
  color: #1f1f8c;
}
.ins-clocking i.fa-plus-circle {
  color: #7d0e0e;
}
.ins-clocking .ins-time {
  font-size: 10px;
  text-align: center;
  margin-top: -2px;
}
</style>
