<template>
  <span>
    <span v-if="showAsMark">
      <i
        class="fa fa-exclamation-circle" v-if="hasValue(element)"
        :title="getValueFieldName(element)"
        v-b-tooltip
      >
      </i>
    </span>
    <span v-else>
      <div v-for="field in fields" :key="field.id" class="small2">
        <div v-if="showFieldValue(element, field)">
          {{ field.text }} : {{ getFieldText(element, field) }}
          <span v-if="field.allowComments" class="field-comments">
            {{ getFieldComments(element, field) }}
          </span>
        </div>
      </div>
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'NurseryFields',
  mixins: [BackendMixin],
  props: {
    fields: Array,
    element: Object,
    showAsMark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    elements: function() {},
    fields: function() {},
  },
  computed: {
  },
  methods: {
    getFieldText(element, field) {
      const fieldTexts = element.fieldTexts || {}
      if (field.field in fieldTexts) {
        return fieldTexts[field.field]
      }
      return ''
    },
    getFieldComments(element, field) {
      const fieldComments = element.fieldComments || {}
      if (field.field in fieldComments) {
        return fieldComments[field.field]
      }
      return ''
    },
    getValue(element, field) {
      let value = false
      if (field.field in element.fieldValues) {
        value = !!element.fieldValues[field.field]
      }
      return value
    },
    hasValue(element) {
      for (const field of this.fields) {
        if (this.getValue(element, field)) {
          return true
        }
      }
      return false
    },
    getValueFieldName(element) {
      for (const field of this.fields) {
        if (this.getValue(element, field)) {
          return field.text
        }
      }
      return ''
    },
    showFieldValue(element, field) {
      // Pour certains champs, nous n'affichons que si la valeur est vraie ou fausse
      if ((!field.falseOnly) && (!field.trueOnly)) {
        return true
      }
      let value = this.getValue(element, field)
      if (field.falseOnly && value) {
        return false
      }
      if (field.trueOnly && !value) {
        return false
      }
      return true
    },
  },
}
</script>
<style lang="less">
.field-comments {
  font-style: italic;
}
</style>
