<template>
  <div style="position: relative; width: 99.9%;">
    <div
      v-for="hour of hours"
      :key="hour"
      :style="hourStyle(hour)"
    >
      {{ hour }}
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { getWeekDay } from '@/utils/dates'
import { addMinutes, compareTimes, diffTimes, displayHourMin, roundedTime } from '@/utils/time'

export default {
  name: 'NurseryInscriptionLineCaption',
  components: {},
  props: {
    limits: Array,
    day: String,
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    dailyLimits() {
      return this.limits.filter(elt => elt.day === getWeekDay(this.day))
    },
    startTime() {
      if (this.dailyLimits.length) {
        return this.dailyLimits[0].startTime
      }
      return null
    },
    endTime() {
      if (this.dailyLimits.length) {
        return this.dailyLimits[this.dailyLimits.length - 1].endTime
      }
      return null
    },
    hours() {
      let hours = []
      for (const limit of this.dailyLimits) {
        hours.push(displayHourMin(limit.startTime))
        let next = roundedTime(limit.startTime, 60)
        if (compareTimes(next, limit.startTime) !== 0) {
          hours.push(displayHourMin(next))
        }
        while (true) {
          next = addMinutes(next, 60)
          if (compareTimes(next, limit.endTime) > 0) {
            hours.push(displayHourMin(next))
          } else {
            break
          }
        }
        hours.push(displayHourMin(limit.endTime))
      }
      return hours
    },
  },
  methods: {
    hourStyle(hour) {
      if (this.hours.length >= 2) {
        let dayDuration = diffTimes(this.hours[this.hours.length - 1], this.hours[0])
        let sliceDuration = diffTimes(this.hours[this.hours.length - 1], hour)
        let offset = 100 - Math.round(10000 * sliceDuration / dayDuration) / 100
        // let offset = Math.round(100 * width * index) / 100
        return {
          'position': 'absolute',
          'display': 'inline-block',
          'left': '' + offset + '%',
          'margin-left': '-10px',
          'font-size': '10px',
          'text-align': 'center',
          'margin-top': '-5px',
        }
      }
      return {
        display: 'none',
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
</style>
