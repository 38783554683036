<template>
  <div style="position: relative; width: 99.5%;">
    <div
      v-for="item of items"
      :key="item"
      :style="itemHoursStyle(item)"
      @click.prevent="onItemClicked(item)"
    >
      {{ getCounter(item) }}
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { getWeekDay } from '@/utils/dates'
import { addMinutes, compareTimes, diffTimes, displayHourMin, roundedTime } from '@/utils/time'

export default {
  name: 'NurseryInscriptionHeader',
  components: {},
  props: {
    limits: Array,
    day: String,
    elements: Array,
    sliceSize: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      selectedItem: '',
    }
  },
  watch: {
  },
  computed: {
    dailyLimits() {
      return this.limits.filter(elt => elt.day === getWeekDay(this.day))
    },
    items() {
      let items = []
      for (const limit of this.dailyLimits) {
        items.push(displayHourMin(limit.startTime))
        let next = roundedTime(limit.startTime, this.sliceSize)
        if (compareTimes(next, limit.startTime) !== 0) {
          items.push(displayHourMin(next))
        }
        while (compareTimes(next, limit.endTime) > 0) {
          next = addMinutes(next, this.sliceSize)
          items.push(displayHourMin(next))
        }
        if (compareTimes(next, limit.endTime) !== 0) {
          items.push(displayHourMin(limit.endTime))
        }
      }
      return items
    },
  },
  methods: {
    itemHoursStyle(item) {
      if (this.items.length >= 2) {
        let dayDuration = diffTimes(this.items[this.items.length - 1], this.items[0])
        let sliceDuration = diffTimes(this.items[this.items.length - 1], item)
        let sliceStep = diffTimes(this.items[1], this.items[0])
        let offset = 100 - Math.round(10000 * sliceDuration / dayDuration) / 100
        const ratio = (this.sliceSize === 60) ? 100 : 165
        let halfSliceSize = Math.round(10000 * sliceStep / dayDuration) / ratio
        return {
          'position': 'absolute',
          'display': 'inline-block',
          'left': '' + (offset + halfSliceSize) + '%',
          'font-size': '10px',
          'text-align': 'center',
          'margin-top': (item === this.selectedItem) ? '-7px' : '-6px',
          'margin-left': (item === this.selectedItem) ? '-2px' : '-1px',
          'cursor': 'pointer',
          'background': (item === this.selectedItem) ? '#ff4961' : 'transparent',
          'color': (item === this.selectedItem) ? '#fff' : '#222',
          'border': (item === this.selectedItem) ? 'solid 1px #ff4961' : 'none',
          'padding': (item === this.selectedItem) ? '1px 2px' : '0',
          'border-radius': '4px',
        }
      }
      return {
        display: 'none',
      }
    },
    getCounter(item) {
      let counter = 0
      for (const elt of this.elements) {
        const matchingInscriptions = elt.inscriptions.filter(
          ins => ins.isHereOnSlice(item)
        )
        if (matchingInscriptions.length > 0) {
          counter++
        }
      }
      return '' + counter
    },
    onItemClicked(item) {
      if (this.selectedItem === item) {
        this.selectedItem = ''
      } else {
        this.selectedItem = item
      }
      this.$emit('click', { item: this.selectedItem, })
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
</style>
